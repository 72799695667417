import React from 'react'
import {
  Loading,
  SEO,
} from '../../ui'
import useStyles from './processing-style'

function ProcessingView({
  seoTitle,
}) {
  const { root } = useStyles()

  return (
    <div>
      <SEO title={seoTitle} />
      <div className={root} />
      <Loading />
    </div>
  )
}

export default ProcessingView
