import React from 'react'
import { useTranslation } from 'react-i18next'
import ProcessingView from './processing-view'

function ProcessingController() {
  // prepare hook
  const { t } = useTranslation()

  // local variable
  const seoTitle = t('screens.checkoutPaymentProcessing.seo.title')

  const viewProps = {
    seoTitle,
  }

  return (
    <ProcessingView {...viewProps} />
  )
}

export default ProcessingController
